<style>

html,
body,
#app, #app > div:first-child{
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
</style>
<script>
export default {
  name: "nothing",
};
</script>

<template>
<div class="container-fluid h-100 p-3">
  <!-- Begin page -->
    <slot />
</div>
</template>