export const menuItems = [
    /*{
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },*/
    /*{
        id: 2,
        label: "menuitems.dashboard.text",
        icon: "uil-home-alt",
        link: "/"
    },*/
    {
        id: 3,
        label: "menuitems.coach.text",
        isTitle: true
    },
    {
        id: 4,
        label: "menuitems.clients.text",
        icon: "uil-users-alt",
        link: "/contacts/list"
    },
    {
        id: 5,
        label: "menuitems.chat.text",
        icon: "uil-comments-alt",
        link: "/tickets",
    },
    {
        id: 6,
        label: "menuitems.planning.text",
        icon: "uil-calender",
        link: "/planning"
    },
    {
        id: 7,
        label: "menuitems.alerts.text",
        icon: "uil-shield-exclamation",
        link: "/alert"
    },
    {
        id: 8,
        label: "menuitems.notifications.text",
        icon: "uil-bell",
        link: "/notifications"
    },
    {
        id: 9,
        label: "menuitems.admin.text",
        isTitle: true
    },
    {
        id: 10,
        label: "menuitems.assign.text",
        icon: "uil-arrow-random",
        link: "/assign"
    },
    {
        id: 11,
        label: "menuitems.revenu.text",
        icon: "uil-euro-circle",
        link: "/revenue"
    },
    {
        id: 12,
        label: "menuitems.moderation.text",
        icon: "uil-bolt-alt",
        link: "/community"
    },
    {
        id: 13,
        label: "menuitems.snella.text",
        icon: "uil-bolt-alt",
        link: "/snella"
    },
    {
        id: 14,
        label: "menuitems.coupons.text",
        icon: "uil-bolt-alt",
        link: "/coupons"
    },
    {
        id: 15,
        label: "menuitems.content.text",
        isTitle: true
    },
    {
        id: 16,
        label: "menuitems.exercices.text",
        icon: "uil-dumbbell",
        link: "/exercices"
    },
    {
        id: 17,
        label: "menuitems.videos.text",
        icon: "uil-video",
        link: "/videos"
    },
    {
        id: 18,
        label: "menuitems.workouts.text",
        icon: "uil-file-copy-alt",
        link: "/basicworkouts"
    },
    {
        id: 19,
        label: "menuitems.programs.text",
        icon: "uil-calendar-alt",
        link: "/basicprograms"
    },
    {
        id: 20,
        label: "menuitems.aliments.text",
        icon: "uil-palette",
        link: "/food"
    },
    {
        id: 21,
        label: "menuitems.recettes.text",
        icon: "uil-utensils",
        link: "/recettes"
    },
    {
        id: 22,
        label: "menuitems.migration.text",
        icon: "uil-utensils",
        link: "/migration"
    },
];

