<script>
import {
    layoutComputed
} from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import Nothing from "./nothing";

/**
 * Main Layout
 */
export default {
    components: {
        Vertical,
        Horizontal,
        Nothing
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    mounted() {
        document.body.classList.remove('authentication-bg')
    },
    methods: {},
};
</script>

<template>
<div>
    <Vertical v-if="layoutType === 'vertical' && !$route.name.includes('Premiumprogramnew') && !$route.name.includes('Premiumprogramedit')" :layout="layoutType">
        <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal' && !$route.name.includes('Premiumprogramnew') && !$route.name.includes('Premiumprogramedit')" :layout="layoutType">
        <slot />
    </Horizontal>

    <Nothing v-if="layoutType === 'vertical' && ($route.name.includes('Premiumprogramnew') || $route.name.includes('Premiumprogramedit'))">
        <slot />
    </Nothing>
</div>
</template>
